import { gtmFbpixel } from '@foundation-lib/analytics'
import wrapRootElement from './src/WrapRootElement'
// import { gtmOnRouteUpdate } from './vendors/gtm'

const onRouteUpdate = () => {
  // // may not need to do this
  // gtmOnRouteUpdate()
}

const onClientEntry = () => {
  window.gtmFbpixel = gtmFbpixel
}

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// eslint-disable-next-line import/prefer-default-export
export { wrapRootElement, onRouteUpdate, onClientEntry }
