import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { Theme } from '@foundation-lib/ui'
import { theme } from './features/global'
import client from './apollo/client'

export default ({ element }) => (
  <ApolloProvider client={client}>
    <Theme theme={theme}>{element}</Theme>
  </ApolloProvider>
)
