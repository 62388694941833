// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-purchase-jsx": () => import("./../src/pages/purchase.jsx" /* webpackChunkName: "component---src-pages-purchase-jsx" */)
}

