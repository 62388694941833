import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { borders, legacyBorders } from './borders';
import { dark as darkColors, light as lightColors, legacy as legacyColors } from './colors';
import { fonts, legacyFonts } from './fonts';
import { legacyShadows, shadows } from './shadows';
export var light = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, lightColors), borders), shadows(lightColors.shadow)), fonts(lightColors.text, lightColors.relativeWhite)), {}, {
  name: 'light'
});
export var dark = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, darkColors), borders), shadows(darkColors.shadow)), fonts(darkColors.relativeWhite, darkColors.text)), {}, {
  // this reversal is on purpose, contrast needs absolute black/white
  name: 'dark'
});
export var legacy = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, legacyColors), legacyBorders), legacyShadows(lightColors.shadow)), legacyFonts(lightColors.text, lightColors.relativeWhite)), {}, {
  name: 'legacy'
});