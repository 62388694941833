import { light } from '@foundation-lib/ui'

const colors = {
  tan: '#f2f0ec',
  darkGray: '#191919',
}

const theme = {
  ...light,
  lightBg: colors.tan,
  darkBg: colors.darkGray,
  primary: colors.darkGray,
  primaryReverse: colors.tan,
  body: '#6f5c37',
}

export default theme
